/*//*/ /*/ Variables /*/ /*//*/
//// Colors ////
$white: #fff;
$black: #000;

$black-brown: #3C3A35;
$egg-shell: #E0DFC6;
$green-brown: #868571;

$light-teal-green: #C3D9D3;
$teal-green: #48c9a6;
$dark-teal-green: #3aa186;

$dark-wood-brown: #7a6b5b;
$wood-brown: #9D8873;
$light-wood-brown: #c4af9a;

$dark-grey: #6B6B6B;
$grey: #A1A1A1;
$light-grey: #D6D6D6;

$dark-blue: #3B8FA8;
$blue: #4FBFE0;
$light-blue: #72CCE6;
$xlight-blue: #DCF2F9;

$dark-green: #269900;
$green: #33CC00;
$light-green: #8FC981;

$dark-yellow: #BE8C24;
$yellow: #FDBB30;
$light-yellow: #FDC959;

$dark-red: #AA2820;
$red: #E3352B;
$light-red: #EE8680;

$dark-orange: #BE8C24;
$orange: #FF8533;
$light-orange: #FDC959;

//// Fonts ////
@font-face {
    font-family: "Mont-Heavy";
    src: url(../fonts/Mont/Mont-Heavy.otf);
}
@font-face {
    font-family: "CaviarDreams";
    src: url(../fonts/CaviarDreams/CaviarDreams.ttf);
}
@font-face {
    font-family: "CaviarDreams-Bold";
    src: url(../fonts/CaviarDreams/CaviarDreams_Bold.ttf);
}

$mont-heavy: "Mont-Heavy", "Arial Bold", sans-serif;
$caviar-dreams: "CaviarDreams", "Arial Regular", sans-serif;
$caviar-dreams-bold: "CaviarDreams-Bold", "Arial Bold", sans-serif;


// Animation underline
  // mixin
  @mixin hover-underline($type: fade, $dir: null, $weight: 2px, $color: $teal-green) {
    @if $dir == null {
      @if $type == fade {
        $dir: 'top';
      } @else if $type == slide {
        $dir: 'center';
      }
    }
  
    position: relative;
    display: inline-block;
    text-decoration: none;
    &::after {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: $weight;
      background: $color;
      @if $type == fade {
        transition: .3s;
        opacity: 0;
        visibility: hidden;
        @if $dir == bottom {
          bottom: $weight * -4;
        } @else {
          bottom: $weight;
        }
      } @else if $type == slide {
        bottom: $weight * -2;
        transform: scale(0, 1);
        transition: transform .3s;
        @if $dir == left-right or $dir == right-in-left {
          transform-origin: left top;
        } @else if $dir == right-left or $dir == left-in-right {
          transform-origin: right top;
        } @else {
          transform-origin: center top;
        }
      }
    }
    &:hover::after {
      @if $type == fade {
        bottom: $weight * -2;
        opacity: 1;
        visibility: visible;
      } @else if $type == slide {
        @if $dir == left-in-right {
          transform-origin: left top;
        } @else if $dir == right-in-left {
          transform-origin: right top;
        }
        transform: scale(1, 1);
      }
    }
  }
  
  // usage
  .example01 a {
    @include hover-underline('fade', 'top');
  }
  .example02 a {
    @include hover-underline('fade', 'bottom');
  }
  .example03 a {
    @include hover-underline('slide', 'left-right');
  }
  .example04 a {
    @include hover-underline('slide', 'right-left');
  }
  .example05 a {
    @include hover-underline('slide', 'left-in-right');
  }
  .example06 a {
    @include hover-underline('slide', 'right-in-left');
  }
  .example07 a {
    @include hover-underline('slide', 'center');
  }