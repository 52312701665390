@import 'variables';

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
  font-family: "Calibri";
  font-size: 16px;
  width: 100%;
  padding: 10px 10px;
  margin: 0 0 5px 0;
  box-sizing: border-box;
  border: 1px solid $dark-grey;
  border-radius: 4px;
  background-color: $white;
  resize: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  outline-color: $blue;
  box-shadow: 0 0 2pt 1pt $blue;
}

textarea {
  font-family: "Calibri";
  font-size: 16px;
  width: 100%;
  height: 150px;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid $dark-grey;
  border-radius: 4px;
  background-color: white;
}

// Buttons
.md-teal-btn {
  background-color: $teal-green;
  font-family: $caviar-dreams-bold !important;
  font-size: 16px;
  border-radius: 4px;
  padding: 10px 35px;
  color: $white;
  border: $teal-green solid 1px; 
  cursor: pointer;
}

.md-teal-btn:hover {
  background-color: $dark-teal-green;
}

.md-teal-btn:focus { 
  border: none;
}

.md-white-btn {
    background-color: $white;
    font-family: $caviar-dreams-bold !important;
    font-size: 16px;
    border-radius: 4px;
    padding: 10px 35px;
    color: $black;
    border: $teal-green solid 1px; 
    cursor: pointer;
  }
  
  .md-white-btn:hover {
    background-color: $light-teal-green;
  }
  
  .md-white-btn:focus { 
    border: none;
  }


  .md-wood-brown-btn {
    background-color: $wood-brown;
    font-family: $caviar-dreams-bold !important;
    font-size: 16px;
    border-radius: 4px;
    padding: 10px 35px;
    color: $white;
    border: $wood-brown solid 1px; 
    cursor: pointer;
  }
  
  .md-wood-brown-btn:hover {
    background-color: $dark-wood-brown;
  }
  
  .md-wood-brown-btn:focus { 
    border: none;
  }