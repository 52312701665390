@import "variables";

.nav-container {
    margin: 0 auto;
}

.nav-logo {
    max-width: 150px;
    vertical-align: middle;
}

.nav-l-text {
    font-size: 3rem;
    margin-top: 20px;
    font-family: $mont-heavy;
    vertical-align: middle;
    display: inline-block;
    color: $white
}

.nav-links {
    float: center;
    text-align: center;
    text-transform: uppercase;
    // padding-right: 50px;
    padding: 25px 0;
}

.nav-link {
    display: inline-block;
    cursor: pointer;
    margin: 0 25px;
    color: $black-brown !important;
    font-family: $caviar-dreams-bold !important;
    font-size: 20px;
    text-decoration: none;
    @include hover-underline('slide', 'center');
  }

.nav-link:hover {
    color: $dark-teal-green !important;
    text-decoration: none;
}

.nav-select {
    color: $teal-green !important;
    position: relative;
    font-weight: 900;
    display: inline-block;
    text-decoration: none;
    &::after {
        position: absolute;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background: $teal-green;
        bottom: 2px * -2;
        transform: scale(0, 1);
        transition: transform .3s;
        transform-origin: center top;
        transform: scale(1, 1);
    }
}

.nav-button {
    color: $white !important;
    margin-left: 25px;
}

/* Mobile */
@media screen and (max-width: 901px) {
    .nav-lg-text {
        font-size: 1.2rem !important;
    }
    
    .nav-logo {
        max-width: 75px;
    }

    .nav-link {
        font-size: 14px !important;
        margin: 0 10px;
    }

    .nav-links {
        padding: 0 0 20px 0;
    }
}

// Mobile
$base-duration: 500ms;

.mobile-nav-logo {
    max-width: 100px;
    vertical-align: middle;
    margin: 10px 0 10px 10px;
}

.hamburger {
	position: fixed;
	top: 30px;
	right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 50px;
	height: 50px;
	background-color: white;
	border-radius: 50%;
	//box-shadow: 0 0 20px rgba(black,0.2);
	transition: transform 250ms ease;
	cursor: pointer;
	z-index: 200;
	
	span {
		position: relative;
		display: block;
		width: 50%;
		height: 2px;
		background-color: $black;
		float: left;
		transform-origin: center center;
		transition: transform 250ms ease;
		z-index: 200;
		
		&:nth-of-type(1) {
			transform: translateY(-5px);
		}
		
		&:nth-of-type(3) {
			transform: translateY(5px);
		}
	}
}

#hamburger {
	display: none;
	
	&:checked ~ .hamburger {
		background-color: transparent;
		transform: rotate(360deg);
		transition: transform 250ms ease;
		
		span {
			background-color: white;
			transition: transform 250ms ease;
			
			&:nth-of-type(1) {
				transform: translateY(1px) rotate(45deg);
			}
			
			&:nth-of-type(2) {
				display: none;
			}
			
			&:nth-of-type(3) {
				transform: translateY(-1px) rotate(-45deg);
			}
		}
	}
	
	&:checked ~ .side-nav {
		right: 0px;
		transition: right $base-duration ease;
	}
	
	&:checked ~ main {
		transform: translateX(250px);
		transition: transform $base-duration ease;
	}
}

.side-nav {
	position: fixed;
	top: 0;
	right: -310px;
	width: 200px;
	height: 100vh;
	margin: 0;
	padding: 0;
	background-color: $black;
	transition: right $base-duration ease;
	z-index: 5;
	
	ul {
		position: relative;
		list-style-type: none;
		margin: 100px 0;
		padding: 0;
		
		li {
			position: relative;
			display: block;
			border-bottom: 5px solid rgba(white,1);
			
			a {
				position: relative;
				display: block;
				margin: 0;
				padding: 15px 20px;
				color: white;
				font-size: 14px;
				font-weight: bold;
				text-decoration: none;
				text-transform: uppercase;
				
				&:before {
					position: absolute;
					content: '';
					top: 0;
					left: 0;
					width: 0;
					height: 100%;
					transition: width 250ms ease;
					z-index: 5;
				}
				
				&:hover {
					color: #444;
					
					&:before {
						width: 100%;
						height: 100%;
						background-color: rgba(white,1);
						transition: width 250ms ease;
					}
				}
			}
		}
	}
}